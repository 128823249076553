import React from "react"

// UI
import Layout from "~components/layout"
import SEO from "~components/seo"
import HomepageHero from "~content/homepage/Hero"
import HomepageCoreFeatures from "~content/homepage/CoreFeatures"
import HomepageProductConstructs from "~content/homepage/ProductConstructs"
import HomepageCanopyOS from "~content/homepage/CanopyOs"
// import HomepageCanopyAPI from "~content/homepage/CanopyApi"
import HomepageForDevelopers from "~content/homepage/ForDevelopers"
import HomepageSecurity from "~content/homepage/Security"
import HomepageIntegrations from "~content/homepage/Integrations"
import HomepageDocs from "~content/homepage/Docs"

const IndexPage: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="Canopy Servicing | The Most Configurable Loan Management & Servicing Platform"
        description="Canopy Servicing enables personalized, transparent, and safer credit and lending products, such as Buy Now Pay Later and modern payment cards."
      />
      <HomepageHero />
      <HomepageCoreFeatures />
      <HomepageProductConstructs />
      <HomepageCanopyOS />
      {/* <HomepageCanopyAPI /> */}
      <HomepageForDevelopers />
      <HomepageSecurity />
      <HomepageIntegrations />
      <HomepageDocs />
    </Layout>
  )
}

export default IndexPage
