import React from "react"

// UI
import Headline from "~ui/Headline"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

// Media
import CanopyAPIEditorImg from "../../images/canopyapi-editor.png"

const HomepageForDevelopers: React.FC = () => (
  <SectionTwoCols className="mb-20 md:mb-40">
    <TwoColCard className="bg-gray-50 dark:bg-gray-900" data-sal="slide-up">
      <Headline level={3} className="mb-6 md:mb-8">
        Built for Developers First
      </Headline>
      <p className="lg:text-lg mb-6 md:mb-8">
        As developers ourselves, we wanted to build a great platform — one we would want to use — with well-documented,
        easy-to-use APIs that maximize flexibility and correctness.
      </p>
      <a href="https://learn.canopyservicing.com/developers-hub" className="lg:text-lg text-blue-500">
        Learn Center: Developers Hub
        <Icon icon={faArrowRight} className="ml-4" />
      </a>
    </TwoColCard>
    <TwoColCard className="bg-blue-100" data-sal="slide-up" data-sal-delay="100">
      <img src={CanopyAPIEditorImg} alt="CanopyAPI" />
    </TwoColCard>
  </SectionTwoCols>
)

export default HomepageForDevelopers
