import React from "react"
// import { Link } from "gatsby"

// UI
import Headline from "~ui/Headline"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"

// Media
import IntegrationsImg from "~images/platform/integrations.png"

const HomepageIntegrations: React.FC = () => (
  <SectionTwoCols className="mb-20 md:mb-40">
    <TwoColCard className="bg-gray-50 dark:bg-gray-900" data-sal="slide-up">
      <Headline level={3} className="mb-6 md:mb-8">
        The Canopy Ecosystem
      </Headline>
      <p className="lg:text-lg mb-6">
        Canopy fits into an ecosystem built to modernize debit cards and money transfer. By plugging in to best-of-breed
        technology partners we provide end-to-end solutions for financial products of all kinds.
      </p>
    </TwoColCard>
    <TwoColCard className="bg-blue-100" data-sal="slide-up" data-sal-delay="100">
      <img src={IntegrationsImg} alt="Canopy Integrations" />
    </TwoColCard>
  </SectionTwoCols>
)

export default HomepageIntegrations
