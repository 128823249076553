import React from "react"

// UI
import SectionThreeCols, { ThreeColCard } from "~components/SectionThreeCols"
import Spirograph from "~components/artwork/Spirograph"
import Aura from "~components/artwork/Aura"
import { faShieldCheck, faChessRook, faBinaryLock } from "@fortawesome/pro-thin-svg-icons"

const HomepageSecurity: React.FC = () => (
  <>
    <Spirograph
      shape={7}
      width="w-0 sm:w-24 lg:w-36"
      position="right-0"
      offsetY="xl:translate-y-0"
      offsetX="sm:translate-x-1/2 xl:-translate-x-1/4"
    />
    <Aura
      size="w-0 sm:w-full"
      position="right-0"
      offsetY="-translate-y-1/3 lg:-translate-y-1/4"
      offsetX="translate-x-3/4"
      opacity="opacity-20"
    />

    <SectionThreeCols
      title={
        <>
          Advanced Security with
          <br className="hidden sm:block" /> Built-in Compliance
        </>
      }
      body="Canopy’s deep commitment to data privacy and security informed the design of our platform from day one and is demonstrated by our rigorous operational controls."
      className="mb-20 md:mb-40"
    >
      <ThreeColCard title="Regulatory compliance" icon={faShieldCheck} data-sal="slide-up">
        <p>Our embedded automations and 100% playback of all borrower activity help ensure your compliance.</p>
      </ThreeColCard>
      <ThreeColCard title="A fortress by design" icon={faChessRook} data-sal="slide-up" data-sal-delay="100">
        <p>Security principles are incorporated into the fundamental design of our platform.</p>
      </ThreeColCard>
      <ThreeColCard title="Data protection" icon={faBinaryLock} data-sal="slide-up" data-sal-delay="200">
        <p>Data is encrypted in transit and at rest. Client data is segregated and access is strictly controlled.</p>
      </ThreeColCard>
    </SectionThreeCols>
  </>
)

export default HomepageSecurity
