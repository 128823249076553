import React from "react"

const Column = (props) => {
  return (
    <div className={`flex filter dark:invert py-2 items-center justify-center ${props.className}`}>
      {props.children}
    </div>
  )
}

const SectionLogos: React.FC<SectionLogosProps> = ({
  className,
  title,
  col1,
  col2,
  col3,
  col4,
  col5,
  col6,
  ...props
}: SectionLogosProps) => {
  const SectionLogosBaseClasses = "container mx-auto px-6 "

  return (
    <div className={SectionLogosBaseClasses + className} {...props}>
      {title && <div className="md:hidden mb-4 text-center">{title}</div>}
      <div className="grid grid-cols-3 md:grid-cols-6">
        {title && <div className="row-span-5 hidden md:flex items-center justify-center">{title}</div>}
        <Column>{col1}</Column>
        <Column>{col2}</Column>
        <Column>{col3}</Column>
        <Column>{col4}</Column>
        <Column>{col5}</Column>
        <Column className="md:hidden">{col6}</Column>
      </div>
    </div>
  )
}

interface SectionLogosProps {
  className?: string
  title?: string | React.ReactNode
  col1: string | React.ReactNode
  col2: string | React.ReactNode
  col3: string | React.ReactNode
  col4: string | React.ReactNode
  col5: string | React.ReactNode
  col6: string | React.ReactNode
}

export default SectionLogos
