import React from "react"

// UI
import Button from "~ui/Button"
import SectionMediaFull from "~components/SectionMediaFull"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faCircleCheck } from "@fortawesome/pro-light-svg-icons"

// Media
import imgPreviewBalanceOffset from "~images/platform/preview-balance-offset.png"

const HomepageCanopyOS: React.FC = () => (
  <SectionMediaFull
    title={
      <>
        <span className="block text-base md:text-lg text-blue-500 mb-2">CanopyOS</span> An Operating System for
        Redefining Loan Management
      </>
    }
    img={imgPreviewBalanceOffset}
    action={
      <Button to="/platform" size="large" style="secondary" icon={faArrowRight}>
        Learn More
      </Button>
    }
    className="mb-20 md:mb-40"
  >
    <SectionTwoCols>
      <TwoColCard className="sm:px-6 2xl:px-8 mb-2 md:mb-0" noPadding>
        <ul className="lg:text-lg">
          <li className="mb-3">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <strong className="text-black dark:text-white">Streamlined dispute processing</strong>
          </li>
          <li className="mb-3">
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <strong className="text-black dark:text-white">Dynamic, retroactive events</strong>
          </li>
          <li>
            <Icon icon={faCircleCheck} size="lg" className="text-blue-500 mr-3" />
            <strong className="text-black dark:text-white">An immutable ledger</strong>
          </li>
        </ul>
      </TwoColCard>
      <TwoColCard className="sm:px-6 2xl:px-8" noPadding>
        <p className="lg:text-lg">
          Most loan management platforms don’t support real-time calculations or process events retroactively. Canopy
          handles dispute processing and captures account changes, whether they occur today or back in time.
        </p>
      </TwoColCard>
    </SectionTwoCols>
  </SectionMediaFull>
)

export default HomepageCanopyOS
