import React from "react"

// UI
import SectionThreeCols, { ThreeColCard } from "~components/SectionThreeCols"
import {
  faShapes,
  faCalendarClock,
  faUserGear,
  faSignalStream,
  faClockRotateLeft,
  faFileInvoice,
} from "@fortawesome/pro-thin-svg-icons"

const HomepageCoreFeatures: React.FC = () => (
  <SectionThreeCols
    title="A Modern Core — and More"
    body="Make targeted and personalized loans with built-in automations and a full suite of APIs. With Canopy’s platform, everything from account setup and payment processing to credit bureau and bank reporting is easier."
    className="mb-16 lg:mb-40"
  >
    <ThreeColCard title="Multi-product capable" icon={faShapes} data-sal="slide-up">
      <p>Manage credit, debit, and installment loans in a single platform.</p>
    </ThreeColCard>
    <ThreeColCard title="Real-time processing" icon={faCalendarClock} data-sal="slide-up" data-sal-delay="100">
      <p>Bid farewell to batch processing. Calculate interest owed as payments are made.</p>
    </ThreeColCard>
    <ThreeColCard title="Account-level policies" icon={faUserGear} data-sal="slide-up" data-sal-delay="200">
      <p>Set interest rates, billing cycles, promotions, and fees per product and per customer.</p>
    </ThreeColCard>
    <ThreeColCard title="Callback automations" icon={faSignalStream} data-sal="slide-up">
      <p>Send payment reminders, notify customers of promotions, waive fees, and more with webhooks.</p>
    </ThreeColCard>
    <ThreeColCard title="Disputes & reversals" icon={faClockRotateLeft} data-sal="slide-up" data-sal-delay="100">
      <p>Retroactively process disputed payments, re-pour payments, and re-calculate interest.</p>
    </ThreeColCard>
    <ThreeColCard title="Real-time statements" icon={faFileInvoice} data-sal="slide-up" data-sal-delay="100">
      <p>Review account status and generate statement data in real-time.</p>
    </ThreeColCard>
  </SectionThreeCols>
)

export default HomepageCoreFeatures
