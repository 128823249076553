import React from "react"

// UI
import SectionHero from "~components/SectionHero"
import SectionMediaFull from "~components/SectionMediaFull"
import SectionLogos from "~components/SectionLogos"
import Spirograph from "~components/artwork/Spirograph"
import Aura from "~components/artwork/Aura"

// Media
import imgPreviewAccountDetail from "~images/platform/preview-account-detail.png"
import PartnerLogoPlaid from "~images/partners/plaid.png"
import PartnerLogoVisa from "~images/partners/visa.png"
import PartnerLogoLithic from "~images/partners/lithic.png"
import PartnerLogoStripe from "~images/partners/stripe.png"
import PartnerLogoCheckout from "~images/partners/checkout.png"
import PartnerLogoBloom from "~images/partners/bloom.png"

const HomepageHero: React.FC = () => (
  <>
    <SectionHero
      title={
        <>
          Build Modern Lending Products, <span className="text-blue-500">Without the Hassle.</span>
        </>
      }
      subtitle="Canopy is the most flexible API platform for loan management and servicing. Gain new market share by offering next-gen credit and lending products."
    />

    <Spirograph
      shape={1}
      width="w-full1/4x sm:w-3/4 md:w-3/4 xl:w-3/5"
      position="right-0"
      offsetY="-translate-y-3/5 sm:-translate-y-3/20 md:-translate-y-3/20"
      offsetX="translate-x-1/2 sm:translate-x-1/3 md:translate-x-2/5"
    />
    <Spirograph
      shape={5}
      width="w-0 md:w-24"
      position="right-0"
      offsetY="md:-translate-y-1/3 xl:-translate-y-3/10 2xl:-translate-y-1/4"
      offsetX="md:translate-x-1/2 xl:translate-x-0"
    />
    <Aura
      size="w-full2x lg:w-full"
      position="left-0"
      offsetY="-translate-y-2/5"
      offsetX="-translate-x-1/2"
      opacity="opacity-30"
    />
    <Aura
      size="w-full2x sm:w-full1/2x md:w-full"
      position="right-0"
      offsetY="-translate-y-1/2 sm:-translate-y-1/3"
      offsetX="translate-x-1/2"
      opacity="opacity-50"
    />
    <Aura
      color="purple"
      size="w-full1/2x md:w-full"
      position="right-0"
      offsetY="-translate-y-3/20 md:-translate-y-1/20"
      offsetX="translate-x-1/2 md:translate-x-3/5"
      opacity="opacity-30"
    />

    <SectionMediaFull img={imgPreviewAccountDetail} className="mb-6 md:mb-12" />

    <SectionLogos
      title={
        <strong className="text-black dark:text-white">
          Partnerships
          <br className="hidden md:block" /> &amp; Integrations
        </strong>
      }
      col1={<img src={PartnerLogoPlaid} alt="Plaid" style={{ maxWidth: "50%" }} />}
      col2={<img src={PartnerLogoVisa} alt="Visa" style={{ maxWidth: "50%" }} />}
      col3={<img src={PartnerLogoLithic} alt="Lithic" style={{ maxWidth: "50%" }} />}
      col4={<img src={PartnerLogoStripe} alt="Stripe" style={{ maxWidth: "50%" }} />}
      col5={<img src={PartnerLogoBloom} alt="Bloom" style={{ maxWidth: "60%" }} />}
      col6={<img src={PartnerLogoCheckout} alt="Checkout.com" style={{ maxWidth: "60%" }} />}
      className="mb-20 md:mb-24 lg:mb-40"
      data-sal="slide-up"
    />
  </>
)

export default HomepageHero
