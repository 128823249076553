import React from "react"
import { Link } from "gatsby"

// UI
import Headline from "~ui/Headline"
import SectionTwoCols, { TwoColCard } from "~components/SectionTwoCols"
import Spirograph from "~components/artwork/Spirograph"
import Aura from "~components/artwork/Aura"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-thin-svg-icons"
import { faCartShopping, faCreditCardBlank, faMoneyCheck, faObjectsColumn } from "@fortawesome/pro-solid-svg-icons"

const ConstructItem = (props) => (
  <Link
    to={props.to}
    className="flex justify-between items-center bg-white hover:bg-gray-50 px-6 md:px-4 lg:px-6 py-3 md:py-5 my-4 lg:text-xl font-display font-bold text-black hover:text-blue-500 shadow-xl rounded-3xl transition duration-200 fs-unmask"
    data-sal="slide-up"
    data-sal-delay={props.animationDelay | 0}
  >
    <div>
      <Icon icon={props.icon} size="lg" className="w-8 mr-4 text-blue-500" />
      {props.title}
    </div>
    <div className="flex md:hidden lg:flex justify-center items-center h-8 w-8 bg-gray-200 rounded-full text-gray-600">
      <Icon icon={faArrowRight} />
    </div>
  </Link>
)

const HomepageProductConstructs: React.FC = () => (
  <>
    <Spirograph
      shape={2}
      width="w-full1/4x sm:w-full lg:w-3/4 xl:w-3/5 2xl:w-1/2"
      position="right-0"
      offsetY="translate-y-1/2 sm:translate-y-2/5 md:-translate-y-1/20 lg:-translate-y-1/10 2xl:-translate-y-1/20"
      offsetX="translate-x-1/2 sm:translate-x-3/5 md:translate-x-3/4 lg:translate-x-3/5 xl:translate-x-1/2 2xl:translate-x-2/5"
    />
    <Spirograph
      shape={6}
      width="w-20 lg:w-24"
      position="left-0"
      offsetY="-translate-y-1/10 md:-translate-y-0 lg:-translate-y-1/20"
      offsetX="-translate-x-1/2 md:-translate-x-2/3 lg:-translate-x-3/5 xl:translate-x-1/2"
    />
    <Aura
      size="w-full2x lg:w-full"
      position="right-0"
      offsetY="translate-y-1/4 sm:-translate-y-1/10 md:-translate-y-1/4"
      offsetX="translate-x-3/5 lg:translate-x-1/2"
      opacity="opacity-30"
    />

    <SectionTwoCols className="mb-20 md:mb-40 relative z-1 md:gap-12 lg:gap-24">
      <TwoColCard className="pb-8 md:pb-0 px-6 2xl:px-8" data-sal="slide-up" noPadding>
        <Headline level={3} className="mb-6 md:mb-8">
          A New Era for Financial Products
        </Headline>
        <p className="lg:text-lg">
          Canopy is a secure, compliant loan management system that removes the constraints of legacy software. The
          platform supports custom product constructs with flexible building blocks for an array of use cases.
        </p>
      </TwoColCard>
      <TwoColCard data-sal="slide-up" data-sal-delay="100" noPadding>
        <div className="-my-4">
          <ConstructItem title="Payment Cards" to="/use-case/payment-cards" icon={faCreditCardBlank} />
          <ConstructItem
            title="Installment Loans"
            to="/use-case/installment-loans"
            icon={faMoneyCheck}
            animationDelay="100"
          />
          <ConstructItem
            title="Buy Now Pay Later"
            to="/use-case/buy-now-pay-later"
            icon={faCartShopping}
            animationDelay="200"
          />
          <ConstructItem
            title="Next-gen Constructs"
            to="/use-case/next-gen-constructs"
            icon={faObjectsColumn}
            animationDelay="300"
          />
        </div>
      </TwoColCard>
    </SectionTwoCols>
  </>
)

export default HomepageProductConstructs
