import React from "react"

// UI
import Button from "~ui/Button"
import Headline from "~ui/Headline"
import SectionCallout from "~components/SectionCallout"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"

const HomepageDocs: React.FC = () => (
  <SectionCallout>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-12">
      <div className="lg:col-span-2">
        <Headline level={3} className="text-white xl:text-3xl mb-4">
          Dig Into Our Documentation
        </Headline>
        <p className="text-white lg:text-lg">
          The Canopy Learn Center is where we help customers get to know our product, and we share what we have learned
          about our industry. We believe the more we learn and the more we share, the better our platform and your
          products will be.
        </p>
      </div>
      <div className="flex flex-col justify-center md:row-span-1">
        <Button to="https://learn.canopyservicing.com/" icon={faArrowRight} size="large" className="w-100 mb-3">
          Learn Center
        </Button>
        <Button
          to="https://learn.canopyservicing.com/overview/go-live-checklist"
          icon={faArrowRight}
          size="large"
          className="w-100"
        >
          Go-Live Checklist
        </Button>
      </div>
    </div>
  </SectionCallout>
)

export default HomepageDocs
